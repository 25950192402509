import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from '../../../assets/styles/form.module.scss'
import { withRouter } from "react-router";

const register = '申し込み'
const confirm  = '確認'
const complete   = '完了'

const NavMenu = (props) => {
  return (
    <div>
      <div className={styles.inner}>
        <ul className={styles.nav_menu}>
          <ListTag title={register} pathname={props.location.pathname} />
          <ListTag title={confirm} pathname={props.location.pathname} />
          <ListTag title={complete} pathname={props.location.pathname} />
        </ul>
      </div>
    </div>
  )
}

const ListTag = (props) => {
  // console.log(props.history)
  return (
    (() => {
      switch (props.pathname) {
        case '/registration':
          return (
            <>
              {(props.title === register ) && (
                <li className={styles.nav_active}>
                  <NavLink to={'/registration'} >{props.title}</NavLink>
                </li>
              )}
              {(props.title === confirm ) && ( <li>{props.title}</li>)}
              {(props.title === complete ) && ( <li>{props.title}</li>)}
            </>
          )
        case '/registration/confirm':
          return (
            <>
              {(props.title === register ) && (
                <li className={styles.nav_active}>
                  <NavLink to={'/registration'} >{props.title}</NavLink>
                </li>
              )}
              {(props.title === confirm ) && (
                <li className={styles.nav_active}>{props.title}</li>
              )}
              {(props.title === complete ) && ( <li>{props.title}</li>)}
            </>
          )
        case '/registration/complete':
          return (
            <>
              <li className={styles.nav_active}>{props.title}</li>
            </>
          )
        default:
          return (
            <>
              <li>{props.title}</li>
            </>
          )
      }

    })()
  )
}

export default withRouter(NavMenu);