import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Amplify, { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { getTempUserData } from '../../graphql/queries';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

// メールアドレスがすでに登録されてないかチェックする。
export const getEmail = createAsyncThunk(
  'authentication/getEmail',
  async (data) => {
    const id = data.id
    const email = data.email

    const res = await API.graphql(graphqlOperation(getTempUserData, {
      id: id
    }))
    const res_email = res.data.getTempUserData.email
    const res_status = res.data.getTempUserData.status
    const response = (res_email === email) && (res_status === 'in progress')
    return response
  }
)

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    data: {},
    isAuthenticated: false,
    status: null,
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: {
    [getEmail.pending]: (state, action) => {
      // console.log(action)
      state.status = 'sending'
    },
    [getEmail.fulfilled]: (state, action) => {
      // console.log(action)
      state.status = 'succeeded'
    },
    [getEmail.rejected]: (state, action) => {
      // console.log(action)
      state.status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
  },
});

export const { setData, setIsAuthenticated } = authenticationSlice.actions;
// export const selectData = state => state.verification.data;

export default authenticationSlice.reducer;
