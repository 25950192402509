import React from "react";
import Header from "../../app/header";
import NavMenu from './components/nav_menu'
import Thanks from "./components/thanks";
import Footer from "../../app/footer";
import styles from '../../assets/styles/form.module.scss'

const Complete = (props) => {

  return (
    <>
      <div className={styles.container}>
        <Header />
        <NavMenu />
        <Thanks />
        <Footer />
      </div>
    </>
  );
}

export default Complete;
