import React, { useEffect } from "react";
// import { useForm } from "react-hook-form";
import styles from '../../../assets/styles/form.module.scss'

import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setData } from '../registrationSlice';
import { setIsAuthenticated } from '../../authentication/authenticationSlice';

const Thanks = (props) => {
  // const { register, handleSubmit, watch, errors } = useForm();

  // console.log(props);
  const dispatch = useDispatch();
  const isFailed = useSelector(state => state.registration.status) === 'failed'; 
  const error = useSelector(state => state.registration.error);

  useEffect(() => {
    return () => {
      dispatch(setData({}));
      dispatch(setIsAuthenticated(false));
    }
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
      {
        isFailed ? 
          <div>{`${error.name}: ${error.message}`}</div>
          : 
          <div className={styles.complete}>
            <div className={styles.explanation_wrapper}>
              <p>My Redmine無料お試しにお申し込みいただき、ありがとうございました。</p>
            </div>

            <div className={styles.explanation_wrapper}>
              <div className={styles.notice}>
                <div>
                  <p className={styles.title}>ログイン方法ご案内のメールを後ほどお送りします。</p>
                  <p>（メールのお届けには、10分から20分程度かかります）</p>
                </div>
              </div>
              <ul>
                <li>数時間以上経ってもメールが届かない場合、恐れ入りますがWebサポート窓口までお問い合わせください。</li>
                <li>同じメールアドレスで無料お試しの登録をする場合は、お申し込みから24時間経過後にお申し込みください。</li>
              </ul>
              <div className={styles.notice}>
                <div>
                  <p><a href='https://www.farend.co.jp/contact/web-support/' target='_blank' rel='noopener noreferrer'>Webサポート窓口</a></p>
                  <p>サポート対応時間：8:30〜17:00(土曜日・日曜日・祝日・弊社休業日を除く)</p>
                </div>
              </div>
            </div>

            <div className={styles.policy_wrapper}>
              <h2><b>My Remineお役立ち資料</b></h2>
              <div className={styles.notice}>
                <div>
                  <p><a href='https://hosting.redmine.jp/startup/manual/' target='_blank' rel='noopener noreferrer'>My Redmine ご利用マニュアル</a></p>
                  <p>はじめてご利用になる方向けに、My Redmineのご利用手順・基本的な操作を紹介しています。</p>
                </div>
              </div>
              <div className={styles.notice}>
                <div>
                  <p><a href='https://hosting.redmine.jp/startup/startup-guide/' target='_blank' rel='noopener noreferrer'>スタートアップガイド</a></p>
                  <p>無料お試し期間中のご利用方法やお手続きについてご案内したガイドです。（冊子版を後日郵送します）</p>
                </div>
              </div>
            </div>
          </div>
      }
      </div>
    </div>
  );
}

export default withRouter(Thanks);