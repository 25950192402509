import React from "react";
import Header from "../../app/header";
import Form from "./components/standard_form";
import Footer from "../../app/footer";
import styles from '../../assets/styles/form.module.scss'

const Register = (props) => {
  
  return (
    <>
      <div className={styles.container}>
        <Header />
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default Register;