/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTempUserData = /* GraphQL */ `
  mutation CreateTempUserData(
    $input: CreateTempUserDataInput!
    $condition: ModelTempUserDataConditionInput
  ) {
    createTempUserData(input: $input, condition: $condition) {
      id
      email
      status
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateTempUserData = /* GraphQL */ `
  mutation UpdateTempUserData(
    $input: UpdateTempUserDataInput!
    $condition: ModelTempUserDataConditionInput
  ) {
    updateTempUserData(input: $input, condition: $condition) {
      id
      email
      status
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteTempUserData = /* GraphQL */ `
  mutation DeleteTempUserData(
    $input: DeleteTempUserDataInput!
    $condition: ModelTempUserDataConditionInput
  ) {
    deleteTempUserData(input: $input, condition: $condition) {
      id
      email
      status
      timestamp
      createdAt
      updatedAt
    }
  }
`;
