import React from "react";
import EmailSubmissionForm from "./common/EmailSubmissionForm";
import styles from '../../../assets/styles/form.module.scss'

const Form = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.form_wrapper}>
          <div className={styles.explanation_wrapper}>
            <h1>My Redmine 無料お試し申し込み</h1>
            <p>My Redmineを無料で翌月末までお試し頂けます。</p>
            <p>メールアドレスを入力してください。ご登録用URLを送信いたします。</p>
          </div>
          <EmailSubmissionForm styles={styles} redirectPath={'./complete'} />
          <div className={styles.policy_wrapper}>
            <p>お預かりした個人情報は以下の目的で、<a href='https://www.farend.co.jp/profile/privacy/' target='_blank' rel='noopener noreferrer'>ファーエンドテクノロジー株式会社 個人情報保護方針</a>に則って使用します。</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
