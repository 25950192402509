import React from "react";
import Header from "../../app/header";
import NavMenu from './components/nav_menu'
import SendData from "./components/send_data";
import Footer from "../../app/footer";
import ScrollToTop from "./components/scroll_to_top";
import styles from '../../assets/styles/form.module.scss'

const Confirm = (props) => {

  return (
    <>
      <div className={styles.container}>
        <ScrollToTop />
        <Header />
        <NavMenu />
        <SendData />
        <Footer />
      </div>
    </>
  );
}
export default Confirm;