import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Amplify, { API } from 'aws-amplify';
import { graphqlOperation } from '@aws-amplify/api';
import { updateTempUserData } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

// Saturnへの登録処理が終了後、DBに登録されているメールアドレスのレコードをdoneに変更する。
export const updateEmail = createAsyncThunk(
  'authentication/updateEmail',
  async (data) => {
    const id = data.id
    const response = await API.graphql(graphqlOperation(updateTempUserData, {
      input: {
        id: id,
        status: 'done'
      }
    }))
    return response
  }
)

// 登録されたデータをSQSへ登録する。
// SQSからLambdaを経由して、Saturnの新規登録用のAPIコールさせる。
export const postData = createAsyncThunk(
  'registration/postData',
  async (def_data) => {
    const apiName = 'ServiceAccountAPI';
    const path = '/v2/user';
    const myInit = { // OPTIONAL
        body: def_data, // replace this with attributes you need
        headers: {}, // OPTIONAL
    };
    const response = await API.post(apiName, path, myInit);  
    return response
  }
)


// 登録があったことを管理者へ通知する。
export const postEmail = createAsyncThunk(
  'registration/postEmail',
  async (def_data) => {
    const apiName = 'NotificationApi';
    const path = '/v2/registration-notice';
    const myInit = { // OPTIONAL
      body: def_data, // replace this with attributes you need
      headers: {}, // OPTIONAL
    };
    return await API.post(apiName, path, myInit);
  }
)

// G1, G2 でアカウント名が利用されていないかチェックする。
export const validateAccount = createAsyncThunk(
  'registration/validateAccount',
  async (account) => {
    const apiName = 'AccountValidation'
    const path = '/v2/account/' + account
    const myInit = { headers: {} }
    const response = await API.get(apiName, path, myInit)
    // console.log(response)
    return response
  }
)

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    data: {},
    status: null,
    validation_status: null,
    postEmail_status: null,
    updateEmail_status:null,
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [postData.pending]: (state, action) => {
      // console.log(action)
      state.status = 'sending'
    },
    [postData.fulfilled]: (state, action) => {
      // console.log(action)
      state.status = 'succeeded'
    },
    [postData.rejected]: (state, action) => {
      // console.log(action)
      state.status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
    [validateAccount.pending]: (state, action) => {
      // console.log(action)
      state.validation_status = 'sending'
    },
    [validateAccount.fulfilled]: (state, action) => {
      // console.log(action)
      state.validation_status = 'succeeded'
    },
    [validateAccount.rejected]: (state, action) => {
      // console.log(action)
      state.validation_status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
    [postEmail.pending]: (state, action) => {
      // console.log(action)
      state.postEmail_status = 'sending'
    },
    [postEmail.fulfilled]: (state, action) => {
      // console.log(action)
      state.postEmail_status = 'succeeded'
    },
    [postEmail.rejected]: (state, action) => {
      // console.log(action)
      state.postEmail_status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
    [updateEmail.pending]: (state, action) => {
      // console.log(action)
      state.updateEmail_status = 'sending'
    },
    [updateEmail.fulfilled]: (state, action) => {
      // console.log(action)
      state.updateEmail_status = 'succeeded'
    },
    [updateEmail.rejected]: (state, action) => {
      // console.log(action)
      state.updateEmail_status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
  },
});

export const { setData } = registrationSlice.actions;
// export const selectData = state => state.registration.data;

export default registrationSlice.reducer;
