import React from "react";
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
// import { createStore } from 'redux';
import { useSelector, Provider } from "react-redux";

import RegisterForm from './features/registration/register';
import Confirm from './features/registration/confirm';
import Complete from './features/registration/complete';

import EmailRegister from './features/verification/standard_index';
// import EmailConfirm from './features/verification/confirm';
import EmailComplete from './features/verification/standard_complete';
import EmbedEmailRegister from './features/verification/iframe_index';
import EmbedEmailComplete from './features/verification/iframe_complete';

import AuthRegister from './features/authentication/index';

import store from './app/store';

export default function App() {
  return (
    <>
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route exact path='/' component={EmailRegister} />
            {/* <Route exact path='/verification/confirm' component={EmailConfirm} /> */}
            <Route exact path='/complete' component={EmailComplete} />
            <Route exact path='/embed' component={EmbedEmailRegister} />
            <Route exact path='/embed-complete' component={EmbedEmailComplete} />

            <Route exact path='/authentication/:id' component={AuthRegister} />

            <PrivateRoute exact path='/registration' component={RegisterForm} />
            <PrivateRoute exact path="/registration/confirm" component={Confirm}/>
            <PrivateRoute exact path='/registration/complete' component={Complete} />

            <Redirect path="*" to="/" />
          </Switch>
        </HashRouter>
      </Provider>
    </>
  );
}
// export default function App() {
//   return (
//     <>
//       <Header />
//       <Form />
//       <Footer />
//     </>
//   );
// }
const PrivateRoute = ({ component: Component, ...rest }) => {
  const mailIsAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  return (
    <Route {...rest} render={(props) => ( 
      mailIsAuthenticated
        ? <Component {...props} />
        : <Redirect to="/"/>
    )}/>
  )
}
