/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AccountValidation",
            "endpoint": "https://hxlbf5a0ec.execute-api.ap-northeast-1.amazonaws.com/develop",
            "region": "ap-northeast-1"
        },
        {
            "name": "NotificationApi",
            "endpoint": "https://h4467cztlc.execute-api.ap-northeast-1.amazonaws.com/develop",
            "region": "ap-northeast-1"
        },
        {
            "name": "ServiceAccountAPI",
            "endpoint": "https://l6sgw8nxj5.execute-api.ap-northeast-1.amazonaws.com/develop",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://im7hsmxz2batnfadrwiqefs5xy.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ap-northeast-1:32657fca-f583-4b72-a19e-b21c67c78848",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_yubBMhMtT",
    "aws_user_pools_web_client_id": "et8h2p5i6o6rs3rmhb34sg2n2",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
