import { configureStore } from '@reduxjs/toolkit';
import registrationReducer from '../features/registration/registrationSlice';
import verificationReducer from '../features/verification/verificationSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';

export default configureStore({
  reducer: {
    registration: registrationReducer,
    verification: verificationReducer,
    authentication: authenticationReducer,
  },
});
