import React from "react";
import { useForm } from "react-hook-form";
import styles from '../../../assets/styles/form.module.scss'

import { withRouter } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit'
import { setData, postData, updateEmail, postEmail } from '../registrationSlice';
import { getEmail } from '../../authentication/authenticationSlice';

// import Amplify, { API } from 'aws-amplify';
// import awsconfig from '../../../aws-exports';
// Amplify.configure(awsconfig);

// async function postData(def_data) { 
//   const apiName = 'ServiceAccountAPI';
//   const path = '/v1/user';
//   const myInit = { // OPTIONAL
//       body: def_data, // replace this with attributes you need
//       headers: {}, // OPTIONAL
//   };
//   return await API.post(apiName, path, myInit);
// }

// postData();

// function getData() { 
//   const apiName = 'ServiceAccountAPI';
//   const path = '/v1/user';
//   const myInit = { // OPTIONAL
//     headers: {}, // OPTIONAL
//   };
//   return API.get(apiName, path, myInit);
// }

// (async function () {
//   const response = await getData();
//   console.log(response);
// })();


const SendData = (props) => {
  const { register, handleSubmit, setError, errors, formState } = useForm();
  const { isSubmitting } = formState;

  // console.log(props);
  const def_data = useSelector(state => state.registration.data);
  const auth_id = useSelector(state => state.authentication.data.id);
  // const def_data = {
  //   address1: "松江市朝日町000",
  //   address2: "aaa",
  //   address3: "aaaa",
  //   department: "aaaa",
  //   email: "taro@farend.jp",
  //   first_name: "太郎",
  //   last_name: "山岡",
  //   organization: "ファーエンドテクノロジー（株）",
  //   tel: "0852123456",
  //   url: "taro",
  //   zip_code: "6900000"
  // }
  const status = useSelector(state => state.registration.status);
  const postEmail_status = useSelector(state => state.registration.postEmail_status);
  const updateEmail_status = useSelector(state => state.registration.updateEmail_status);
  const authentication_status = useSelector(state => state.authentication.status);
  const authentication_data = useSelector(state => state.authentication.data);

  const isSending = (status === 'sending') || (postEmail_status === 'sending') || (updateEmail_status === 'sending') || (authentication_status === 'sending');
  const isFailed = (status === 'failed') || (postEmail_status === 'failed') || (updateEmail_status === 'failed') || (authentication_status === 'failed');
  const dispatch = useDispatch();
  const onSubmit = async data => {
    dispatch(setData(data));

    const {comparison, industry, numberOfPeople, exp, consideration, purpose, role, source} = data;
    const sourceTmp = {
      "業種" : industry,
      "役割" : role,
      "人数" : numberOfPeople,
      "利用経験" : exp,
      "検討状況" : consideration,
      "情報源" : source,
      "比較" : comparison,
      "目的" : purpose
    }
    let sendData = {};
    const send_keys = Object.keys(data).filter((key) => {
      return !['comparison', 'industry', 'numberOfPeople', 'exp', 'consideration', 'purpose', 'role', 'source'].includes(key);
    });
    send_keys.forEach((key) => {
      sendData[key] = data[key];
    })
    sendData['source'] = [JSON.stringify(sourceTmp)];

    try {
      const getEmail_result = await dispatch(getEmail({
        id: authentication_data.id,
        email: authentication_data.email
      }))
      let isValid = getEmail_result.payload
      unwrapResult(getEmail_result)
      if(isValid) {
        const updateEmail_result = await dispatch(updateEmail({id: auth_id}))
        // rejectedの場合は例外をスロー
        unwrapResult(updateEmail_result);
        const postData_result = await dispatch(postData(sendData))
        // rejectedの場合は例外をスロー
        unwrapResult(postData_result);
        // 登録通知（管理者向け）のメール送信処理。
        // 運用開始時に有効にする。
        const postEmail_result = await dispatch(postEmail(sendData));
        // const send_response = await postEmail(data);
        // console.log(send_response);
        // rejectedの場合は例外をスロー
        unwrapResult(postEmail_result);
        props.history.push("/registration/complete");
      } else {
        setError("email", {
          type: "manual", 
          message: "同じメールアドレスで無料お試しの登録する場合は、24時間経過後にお申し込みください。"
        })
      }
    } catch (err) {
      console.log('Failed to send the data: ', err);
    }
  };

  const input_component = (view_name, key) => {
    let data;
    if(key === 'select_plan') {
      // standard or enterprise
      data = def_data[key] === 'standard' ? ['スタンダード'] : ['エンタープライズ']
    } else {
      // テキスト中の改行コードを<br>に変換して表示
      data = def_data[key]?.split(/(\n)/g)
    }
    return (
      <div className={styles.input_row}>
        <span className={styles.input_label}><label htmlFor={key}>{view_name}</label></span>
        <span className={styles.value_wrap}>
          {data && data.map((value, index) => value === "\n" ? <br key={index} /> : value)}
        </span>
        <input type='hidden' name={key} defaultValue={def_data[key]} ref={register()} />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>

        <div className={styles.explanation_wrapper}>
          <p>まだ登録は完了していません。</p>
          <p>ご入力内容をご確認頂き、ページ下部の「申し込む」ボタンをクリックしてください。</p>
        </div>

        <div className={styles.form_wrapper}>

          <form onSubmit={handleSubmit(onSubmit)} className={styles.registration}>

            <div className={styles.input_wrapper}>

              <div className={styles.input_row}>
                <span className={styles.input_label}><label htmlFor='url'>希望URL</label></span>
                <div className={styles.input_col}>
                  <span className={styles.input_wrap}>{def_data.url}.cloud.redmine.jp</span>
                  <input type='hidden' name="url" ref={register({ required: true })} defaultValue={ def_data ? def_data.url : ''} />
                </div>
              </div>

              <div className={styles.input_row}>
                <span className={styles.input_label}><label htmlFor='user_name'>お申込担当者氏名</label></span>
                <div className={styles.input_col}>
                  <span className={styles.value_wrap}>{ def_data.last_name + ' ' + def_data.first_name}</span>
                  <input type='hidden' name="user_name" ref={register({ required: true })} defaultValue={ def_data.last_name + ' ' + def_data.first_name} />
                </div>
              </div>

              {input_component('メールアドレス', 'email')}
              {input_component('プラン（無料お試しの環境）', 'select_plan')}
              {input_component('法人/個人', 'corporateIndivisual')}
              {input_component('会社・団体', 'organization')}
              {input_component('部署名', 'department')}
              {input_component('郵便番号', 'zip_code')}
              {input_component('住所１（都道府県市町村）', 'address1')}
              {input_component('住所２（番地）', 'address2')}
              {input_component('ビル・マンション名', 'address3')}
              {input_component('電話番号', 'tel')}
              {input_component('業種', 'industry')}
              {input_component('あなたの役割を教えてください', 'role')}
              {input_component('ご利用予定人数', 'numberOfPeople')}
              {input_component('Redmineのご利用経験を教えてください', 'exp')}
              {input_component('参考までに現在のご検討状況を教えてください', 'consideration')}
              {input_component('比較検討されるサービスについて教えて下さい', 'comparison')}

              <div className={styles.input_row}>
                <span className={styles.input_label}><label>ご利用の目的を教えて下さい(複数選択可)</label></span>
                {
                  def_data.purpose?.map((item, index) => (
                    <div className={styles.input_col} key={index}>
                      <span className={styles.value_wrap}>{item === 'その他' ? `${item} : ${def_data.purpose_other_value}` : item}</span>
                      <input name={`purpose[${index}]`} type="hidden" value={item === 'その他' ? `${item} : ${def_data.purpose_other_value}` : item} ref={register} />
                    </div>
                  ))
                }
              </div>

              <div className={styles.input_row}>
                <span className={styles.input_label}><label>My Redmineをどうやってお知りになりましたか</label></span>
                {
                  def_data.source ? (
                    <div className={styles.input_col}>
                      <span className={styles.value_wrap}>{def_data.source === 'その他' ? `その他 : ${def_data.source_other_value}` : def_data.source}</span>
                      <input name='source' type="hidden" value={def_data.source === 'その他' ? `その他 : ${def_data.source_other_value}` : def_data.source} ref={register} />
                    </div>
                  ) : null
                }
              </div>

            </div>

            { isFailed && <div className={styles.prosess_error_message}>エラーが発生しました。しばらく時間を置いてからお試しください。</div> }
            { (errors['email'] && errors['email'].types) && <div className={styles.prosess_error_message}>{ errors['email'].types.message }</div> }

            <div className={styles.submit_btn_wrapper}>
              <button type="submit" className={styles.submit_btn} disabled={isSending || isSubmitting}>
                { isSending ? <span>送信中<span className={styles.sending}></span></span> : <span>申し込む</span> }
              </button>
            </div>

          </form>

          <div className={styles.back_btn_wrapper}>
            <NavLink className={styles.back_btn} to={'/registration'} >戻る</NavLink>
          </div>

        </div>
      </div>
    </div>
  );
}

export default withRouter(SendData);