import React from "react";
import styles from '../../assets/styles/iframe_form.module.scss'
import EmailSubmissionForm from "./components/common/EmailSubmissionForm";

const Register = () => {
  return (
    <div className={styles.form_wrapper}>
      <div className={styles.container}>
        <EmailSubmissionForm
          styles={styles}
          redirectPath={"./embed-complete"}
        />
      </div>
    </div>
  );
};

export default Register;
