import React from "react";
import styles from '../../assets/styles/iframe_form.module.scss'

const Complete = () => {
  return (
    <div className={styles.form_wrapper}>
      <div className={styles.container}>
        <div className={styles.mr_form_finish}>
          <h3>ご登録用メールを送信しました</h3>
          <p>
            メールに記載のURLより、ご登録をお願いいたします。
            <span>メールの有効期限：24時間</span>
          </p>
          <p>
            24時間経ってもメールが届かない場合、
            <a
              href="https://www.farend.co.jp/contact/web-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Webサポート窓口
            </a>
            までお問い合わせください。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Complete;
