import React from "react";
import { useForm } from "react-hook-form";
import styles from '../../../assets/styles/form.module.scss'
import { unwrapResult } from '@reduxjs/toolkit'

import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setData, setIsAuthenticated, getEmail } from '../authenticationSlice';
import { UilCheckCircle } from '@iconscout/react-unicons'
import { UilEnvelopeCheck } from '@iconscout/react-unicons'

const input_props = [
  {
    label_name: "メールアドレス", name: "email",
    required: '必須項目です。',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "メールアドレスが正しくありません。"
    },
    notes: "ご登録用に入力したメールアドレスを入力"
  },
]

const Form = (props) => {
  const { register, handleSubmit, setError, errors, formState } = useForm();
  const { isSubmitting } = formState;

  const status = useSelector(state => state.authentication.status)
  const isChecking = status === 'sending'
  const isFailed = status === 'failed'

  const def_data = useSelector(state => state.registration.data);
  const dispatch = useDispatch();
  const onSubmit = async data => {
    data.email = data.email.toLowerCase()
    const email = data.email
    const user_data = {
      id: props.match.params.id,
      email: email
    }
    data.id = props.match.params.id
    dispatch(setData(data));
    // console.log(data)
    let is_valid
    try {
      const resultAction = await dispatch(getEmail(user_data))
      is_valid = resultAction.payload
      // rejectedの場合は例外をスロー
      unwrapResult(resultAction);
      if(is_valid) {
        dispatch(setIsAuthenticated(is_valid))
        props.history.push("/registration");
      } else {
        setError("email", {
          type: "manual", 
          message: "ご登録用に入力したメールアドレスを入力してください。（またはすでに登録済みです。）"
        })
      }
    } catch (err) {
      console.log('Failed to send the data: ', err);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>

        <div className={styles.complete}>
          <p className={styles.success_message}>
            <UilCheckCircle color="#3ea757" style={{verticalAlign: 'middle', marginRight: '5px'}} />
            ありがとうございます。お手続きは順調です。
          </p>
        </div>

        <div className={styles.form_wrapper}>
          { isFailed && <div className={styles.prosess_error_message}>エラーが発生しました。しばらく時間を置いてからお試しください。</div> }
          <div className={styles.complete}>
            <div className={styles.notice}>
              <div className={styles.icon_wrapper}>
                <UilEnvelopeCheck width="100%" height="100%" color="#3ea757" />
              </div>
              <div>
                <p>メールアドレスが正しいことを確認するため、最初に入力したメールアドレスを入力してください。</p>
              </div>
            </div>           
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.registration}>

            {
              input_props.map((item, index) => (

                <div className={`${styles.input_row} ${errors[item.name] ? styles.input_has_error : ''}`} key={index} >

                  {
                    (() => {
                      // console.log(item.type)
                      switch (item.type) {
                        default:
                          return (
                            <>
                            <span className={styles.input_label}><label htmlFor={item.name}>{item.label_name}</label>{ item.required && <span className={styles.required_tag}>必須</span> }</span>
                            <span className={styles.input_value}>
                              <input name={item.name} ref={register(
                                {
                                  required: item.required,
                                  ...(item.pattern ? { pattern: {
                                      value: new RegExp(item.pattern.value),
                                      message: item.pattern.message
                                  }} : null),
                                  ...(item.max ? { max: item.max } : null),
                                  ...(item.min ? { min: item.min } : null),
                                  ...(item.maxLength ? { maxLength: item.maxLength } : null),
                                  ...(item.minLength ? { minLength: item.minLength } : null),
                                  validate: value => /^\s+$/.test(value) ? '空白のみは入力できません。' : true,
                                }
                              )} defaultValue={ def_data[item.name] ? def_data[item.name]: ''}
                              autoFocus={true} />
                            </span>
                            { errors[item.name] && <span className={styles.errors}>{ errors[item.name].message }</span> }
                            { (errors[item.name] && errors[item.name].types) && <span className={styles.errors}>{ errors[item.name].types.message }</span> }
                            { item.placeholder && <span className={styles.notes_wrapper}>{item.placeholder}</span> }
                            { item.notes && <span className={styles.notes_wrapper}>{item.notes}</span> }
                            </>
                          )
                      }
                    })()
                  }

                </div>

              ))
            }

            <div className={styles.submit_btn_wrapper}>
              <button type="submit" className={styles.confirm_btn} disabled={isChecking || isSubmitting}>
                { isChecking ? <span>確認中<span className={styles.sending}></span></span> : <span>次へ</span> }
              </button>
              <p className={styles.note}>ご登録画面に進みます。</p>
            </div>

          </form>
        </div>

      </div>
    </div>
  );
}

export default withRouter(Form);