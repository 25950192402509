import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Amplify, { API } from 'aws-amplify';
// import { graphqlOperation } from '@aws-amplify/api';
import { createTempUserData } from '../../graphql/mutations';
import awsconfig from '../../aws-exports';
Amplify.configure(awsconfig);

// メールアドレスを認証するために一時的にDBにメールアドレスを保存する。
// 最初のメールアドレス登録の画面で実行される。
export const postData = createAsyncThunk(
  'verification/postData',
  async (email) => {

    const TempUserDetail = {
      email: email,
      status: 'in progress',
      timestamp: Date.now(),
    }
    const response = await API.graphql({
      query: createTempUserData, 
      variables: {input: TempUserDetail},
      authMode: 'AWS_IAM'
    });

    return response
  }
)

// メールアドレスの登録者に認証用のURLを送信する。
export const postEmail = createAsyncThunk(
  'verification/postEmail',
  async (data) => {
    const apiName = 'NotificationApi';
    const path = '/v2/notification';
    const myInit = { // OPTIONAL
      body: {
        id: data.id, // replace this with attributes you need
        email: data.email, // replace this with attributes you need
      },
      headers: {}, // OPTIONAL
    };
    const response = await API.post(apiName, path, myInit);  
    return response
  }
)

export const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    data: {},
    data_status: null,
    email_status: null,
    error: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [postData.pending]: (state, action) => {
      // console.log(action)
      state.data_status = 'sending'
    },
    [postData.fulfilled]: (state, action) => {
      // console.log(action)
      state.data_status = 'succeeded'
    },
    [postData.rejected]: (state, action) => {
      // console.log(action)
      state.data_status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
    [postEmail.pending]: (state, action) => {
      // console.log(action)
      state.email_status = 'sending'
    },
    [postEmail.fulfilled]: (state, action) => {
      // console.log(action)
      state.email_status = 'succeeded'
    },
    [postEmail.rejected]: (state, action) => {
      // console.log(action)
      state.email_status = 'failed'
      if (action.payload) {
        // If a rejected action has a payload, it means that it was returned with rejectWithValue
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    },
  },
});

export const { setData } = verificationSlice.actions;

export default verificationSlice.reducer;
