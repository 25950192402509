/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTempUserData = /* GraphQL */ `
  query GetTempUserData($id: ID!) {
    getTempUserData(id: $id) {
      id
      email
      status
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listTempUserDatas = /* GraphQL */ `
  query ListTempUserDatas(
    $filter: ModelTempUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTempUserDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        status
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getByEmail = /* GraphQL */ `
  query GetByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelTempUserDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
