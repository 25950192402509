import React from "react";
import logo from "../assets/images/my-redmine-logo.png"
import logo2x from "../assets/images/my-redmine-logo@2x.png"
import styles from '../assets/styles/form.module.scss'

export default function Header() {
  return (
    <>
    <header>
      <div className={styles.logo_wrapper}>
        <img src={logo} srcSet={`${logo}, ${logo2x} 2x`} alt="My Redmine" />
      </div>
    </header>
    </>
  )
}